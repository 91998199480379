'use client';

import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';

//import { usePathname } from 'next/navigation';
import AppStorage from '@/utils/helpers';
import LoginContent from './LoginContent';
import SignupContent from './SignupContent';
import { XMarkIcon } from '@heroicons/react/24/outline';

type OpenType = {
  open: boolean;
  state: string;
};

type LoginModalType = {
  open: OpenType;
  closeModal: () => void;
  swicthToRegister: () => void;
  switchToLogin: () => void;
};

const AuthModal = ({
  open,
  closeModal,
  switchToLogin,
  swicthToRegister,
}: LoginModalType) => {
  const pathname = usePathname();
  const router = useRouter();
  open ?? router.push('/checkout/billing');

  useEffect(() => {
    AppStorage.save('pathName', pathname);
  }, []);

  return (
    <Transition appear show={open.open} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="flex flex-col w-full max-w-2xl transform overflow-hidden rounded-lg bg-white p-0 text-left align-middle shadow-xl transition-all">
                <div className="grow w-full">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="p-3 rounded-full w-fit mt-5 ml-5 bg-white hover:bg-gray-100 border border-gray-200"
                  >
                    <XMarkIcon className="w-6 h-6" />
                  </button>

                  <Dialog.Title
                    as="div"
                    className="text-lg font-medium leading-6 text-gray-900 flex px-6 items-center"
                  >
                    {/* <button type="button" onClick={closeModal} ><XMarkIcon className="w-6 h-6" /></button> */}
                    <div className="flex w-full justify-center items-center flex-col">
                      <div className="grow  font-bold text-2xl text-center">
                        {open.state === 'register'
                          ? 'Sign up to Crunchies'
                          : 'Log in to Crunchies'}{' '}
                      </div>
                      {open.state === 'register' ? (
                        <p className="text-center mt-5 text-gray-500 text-sm">
                          Have an account already?{' '}
                          <span
                            className="text-primary-400 cursor-pointer"
                            role="button"
                            onClick={switchToLogin}
                          >
                            Login
                          </span>
                        </p>
                      ) : (
                        <p className="text-center mt-5 text-gray-500 text-sm">
                          Dont have an account?{' '}
                          <span
                            className="text-primary-400 cursor-pointer"
                            role="button"
                            onClick={swicthToRegister}
                          >
                            Create account
                          </span>
                        </p>
                      )}
                    </div>
                  </Dialog.Title>
                  {open.state === 'register' ? (
                    <SignupContent closeModal={closeModal} />
                  ) : (
                    <LoginContent closeModal={closeModal} />
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AuthModal;
