'use client';

import { Fragment, useState, useCallback, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  ChevronLeftIcon,
  EnvelopeIcon,
  LockClosedIcon,
  PhoneIcon,
  UserIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { usePathname, useRouter } from 'next/navigation';
import Link from 'next/link';

import imgGoogle from '@/assets/icons8-google-48.png';
import imgFacebook from '@/assets/icons8-facebook-48.png';
import Image from 'next/image';
import { validateAll } from 'indicative/validator';
import { Register, UserProps } from '@/services/auth.api';
import { useDispatch, useSelector } from 'react-redux';
import { signinUser } from '@/redux/slices/auth.slice';
import { AddToCart } from '@/services/cart.api';
import { toast } from 'react-toastify';
import { getErrorMessge } from '@/utils/helpers';
import AppStorage from '@/utils/helpers';

type SignupModalProps = {
  closeModal: () => void;
};

const SignupContent = ({ closeModal }: SignupModalProps) => {
  const router = useRouter();
  const [errors, setErrors] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    password: '',
  });
  const [isValid, setIsValid] = useState(false);
  const dispatch = useDispatch();
  const { cart } = useSelector((state: any) => state.cart);
  const { user } = useSelector((state: any) => state.auth);
  const [isUser, setIsUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [asyncError, setAsyncError] = useState('');
  const [formData, setFormData] = useState<any>({
    fullName: '',
    email: '',
    phoneNumber: '',
    password: '',
  });

  const pathname = usePathname();

  const handleInputChange = (name: string, value: any) => {
    setFormData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  useEffect(() => {
    if (
      formData.fullName &&
      formData.email &&
      formData.phoneNumber.length > 10 &&
      formData.password.length > 6
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [
    formData.fullName,
    formData.email,
    formData.phoneNumber,
    formData.password,
  ]);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValid) {
      return;
    }

    const validationRules = {
      fullName: 'required',
      email: 'required|email',
      phoneNumber: 'required|min:11|max:15',
      password: 'required|min:6',
    };

    const validationMessages = {
      'fullname.required': 'Full name is required',
      'email.required': 'Email address is required',
      'email.email': 'Email address is not valid',
      'phoneNumber.required': 'Phone number is reqired',
      'phoneNumber.min': 'Phone number is not valid',
      'phoneNumber.max': 'Phone number is not valid',
      'password.required': 'Password is required',
      'password.min': 'Password must be atleast 6 characters long',
    };

    validateAll(formData, validationRules, validationMessages)
      .then(() => {
        registerUser();
      })
      .catch((err: any) => {
        if (Array.isArray(err)) {
          const formattedErrors: any = {};
          err.forEach((err) => {
            formattedErrors[err.field] = err.message;
          });

          setErrors(formattedErrors);
        }
      });
  };

  const registerUser = async () => {
    if (!formData.fullName.split(' ')[0] || !formData.fullName.split(' ')[1]) {
      toast.error('Please enter full name');
      return;
    } else {
      try {
        setLoading(true);
        const payload = {
          first_name: formData.fullName.split(' ')[0],
          last_name: formData.fullName.split(' ')[1],
          telephone: formData.phoneNumber,
          email: formData.email,
          password: formData.password,
          date_of_birth: '1998-09-05',
        };

        const res = await Register(payload);

        dispatch(signinUser(res));

        setIsUser(true);
        const path = AppStorage.get('pathName') as string;
        router.push(path);
        closeModal();

        AppStorage.remove('pathName');
      } catch (error: any) {
        setLoading(false);

        const errorMsg = getErrorMessge(error);
        toast.error(errorMsg);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const addToCart = async () => {
      // if (cart.length > 0 && isUser) {

      const menuItems = cart.map((cartItem: any) => ({
        menuId: cartItem.id,
        quantity: cartItem.quantity,
      }));

      const data = {
        user_id: user?._id,
        menu_items: menuItems,
      };

      try {
        await AddToCart(data);
      } catch (error) {
        // }
      }
    };

    addToCart();
  }, [cart, isUser]);

  useEffect(() => {
    setTimeout(() => {
      setAsyncError('');
    }, 10000);
  }, []);

  return (
    <form
      onSubmit={handleFormSubmit}
      className="flex flex-col gap-4 w-full px-4 lg:px-24 py-8"
    >
      <ul className=" flex flex-col gap-5 w-full ">
        <li>
          <div
            onClick={() =>
              location.replace(
                ' https://api.crunchiesonline.com/api/v1/auth/google/callback'
              )
            }
            className="flex gap-4 items-center justify-center text-center font-semibold p-4 w-full rounded-full border border-gray-200"
          >
            <Image src={imgGoogle} alt="google icon" className="w-6 h-6" />{' '}
            Continue with Google
          </div>
        </li>
        {/* <li>
                                                <Link href={''} className="flex gap-4 items-center justify-center text-center font-semibold p-4 w-full rounded-full border border-gray-200"><Image src={imgFacebook} alt="google icon" className='w-6 h-6' /> Continue with Facebook</Link>
                                            </li> */}
      </ul>
      <div className="flex flex-nowrap gap-3 items-center w-full py-8 px-8">
        <span className="flex border-b-2 grow"></span>
        <small className="text-secondary-base">Continue using Email</small>
        <span className="flex border-b-2 grow"></span>
      </div>
      <form action="" className="flex flex-col gap-3 mb-8">
        <div
          className={`flex items-center gap-2 text-sm py-3 px-3 ${
            errors.fullName ? 'border-red-600' : 'border-gray-200'
          } border rounded-md`}
        >
          <UserIcon className="w-4 h-4 text-gray-500" />
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            placeholder="Full name"
            className="outline-0 grow font-light"
          />
        </div>
        {errors.fullName && <p className="text-red-600 ">{errors.fullName}</p>}
        <div
          className={`flex items-center gap-2 text-sm py-3 px-3 ${
            errors.fullName ? 'border-red-600' : 'border-gray-200'
          } border rounded-md`}
        >
          <EnvelopeIcon className="w-4 h-4 text-gray-500" />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            placeholder="Email"
            className="outline-0 grow font-light"
          />
        </div>
        {errors.email && <p className="text-red-600 ">{errors.email}</p>}
        <div
          className={`flex items-center gap-2 text-sm py-3 px-3 ${
            errors.fullName ? 'border-red-600' : 'border-gray-200'
          } border rounded-md`}
        >
          <PhoneIcon className="w-4 h-4 text-gray-500" />
          <input
            type="tel"
            name="phoneNumber"
            placeholder="Phone Number"
            value={formData.phoneNumber}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            className="outline-0 grow font-light"
          />
        </div>
        {errors.phoneNumber && (
          <p className="text-red-600 ">{errors.phoneNumber}</p>
        )}
        <div
          className={`flex items-center gap-2 text-sm py-3 px-3 ${
            errors.fullName ? 'border-red-600' : 'border-gray-200'
          } border rounded-md`}
        >
          <LockClosedIcon className="w-4 h-4 text-gray-500" />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            className="outline-0 grow font-light"
          />
        </div>
        {errors.password && <p className="text-red-600 ">{errors.password}</p>}
      </form>
      <div className="flex flex-col gap-4">
        <button
          type="submit"
          className={`block text-center font-semibold p-4 w-full rounded-full ${
            isValid ? 'bg-primary-400' : 'bg-gray-400'
          } text-white`}
        >
          {loading ? 'Loading...' : 'Signup'}
        </button>
        <small className="text-gray-500 text-center">
          By creating an account, you automatically accept our Terms of Services
          Privacy Policy and Cookies Policy
        </small>
      </div>
    </form>
  );
};
export default SignupContent;
