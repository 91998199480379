'use client';
import { GoogleAuth, Login } from '@/services/auth.api';
import { AddToCart } from '@/services/cart.api';
import { signinUser } from '@/redux/slices/auth.slice';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import EnvelopeIcon from '@heroicons/react/24/solid/EnvelopeIcon';
import { validateAll } from 'indicative/validator';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import imgGoogle from '@/assets/icons8-google-48.png';
import imgFacebook from '@/assets/icons8-facebook-48.png';
import Image from 'next/image';
import axiosInstance from '@/utils/https';
import { toast } from 'react-toastify';
import { getErrorMessge } from '@/utils/helpers';
import AppStorage from '@/utils/helpers';

type LoginModalProps = {
  closeModal: () => void;
};

function LoginContent({ closeModal }: LoginModalProps) {
  const router = useRouter();
  const [isValid, setIsValid] = useState(false);
  const { cart } = useSelector((state: any) => state.cart);
  const { user } = useSelector((state: any) => state.auth);
  const [isUser, setIsUser] = useState(false);
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    password: '',
    async: '',
  });
  const [asyncError, setAsyncError] = useState('');
  const dispatch = useDispatch();

  const [formData, setFormData] = useState<any>({
    fullName: '',
    email: '',
    phoneNumber: '',
    password: '',
  });

  useEffect(() => {
    if (formData.email && formData.password.length > 6) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [formData.email, formData.password]);

  const handleInputChange = (name: string, value: any) => {
    setFormData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValid) {
      return;
    }

    const validationRules = {
      email: 'required|email',

      password: 'required|min:6',
    };

    const validationMessages = {
      'email.required': 'Email address is required',
      'email.email': 'Email address is not valid',

      'password.required': 'Password is required',
      'password.min': 'Password must be atleast 6 characters long',
    };

    validateAll(formData, validationRules, validationMessages)
      .then(() => {
        login();
      })
      .catch((err: any) => {
        if (Array.isArray(err)) {
          const formattedErrors: any = {};
          err.forEach((err) => {
            formattedErrors[err.field] = err.message;
          });

          setErrors(formattedErrors);
        }
      });
  };

  const login = async () => {
    try {
      setLoading(true);
      const payload = {
        email: formData.email,
        password: formData.password,
      };
      const res = await Login(payload);
      dispatch(signinUser(res));
      setIsUser(true);
      const path = AppStorage.get('pathName') as string;
      router.push(path);
      closeModal();
      AppStorage.remove('pathName');
    } catch (error: any) {
      setLoading(false);
      const errorMsg = getErrorMessge(error);
      toast.error(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const addToCart = async () => {
      const menuItems = cart.map((cartItem: any) => ({
        menuId: cartItem.id,
        quantity: cartItem.quantity,
      }));

      const data = {
        user_id: user?._id,
        menu_items: menuItems,
      };

      try {
        const res = await AddToCart(data);
      } catch (error) {
        // }
      }
    };

    addToCart();
  }, [cart, isUser]);

  useEffect(() => {
    setTimeout(() => {
      setAsyncError('');
    }, 10000);
  }, []);

  return (
    <section className="flex flex-col gap-4 w-full px-4 lg:px-24 py-10">
      <ul className=" flex flex-col gap-5 w-full ">
        <li>
          <div
            onClick={() =>
              location.replace(
                ' https://api.crunchiesonline.com/api/v1/auth/google/callback'
              )
            }
            className="flex gap-4 items-center justify-center text-center font-semibold p-4 w-full rounded-full border border-gray-200"
          >
            <Image src={imgGoogle} alt="google icon" className="w-6 h-6" />{' '}
            Continue with Google
          </div>
        </li>
        {/* <li>
                                                <div  className="flex gap-4 items-center justify-center text-center font-semibold p-4 w-full rounded-full border border-gray-200"><Image src={imgFacebook} alt="google icon" className='w-6 h-6' /> Continue with Facebook</div>
                                            </li> */}
      </ul>
      <div className="flex flex-nowrap gap-3 items-center w-full py-8 px-8">
        <span className="flex border-b-2 grow"></span>
        <small>OR</small>
        <span className="flex border-b-2 grow"></span>
      </div>
      <div className="flex flex-col">
        <form onSubmit={handleFormSubmit} className="">
          <div
            className={`flex mb-5 items-center gap-2 text-sm py-3 px-3 ${
              errors.fullName ? 'border-red-600' : 'border-gray-200'
            } border rounded-md`}
          >
            <EnvelopeIcon className="w-4 h-4 text-gray-500" />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
              placeholder="Email"
              className="outline-0 grow font-light"
            />
          </div>
          <div
            className={`flex my-2 items-center gap-2 text-sm py-3 px-3 ${
              errors.fullName ? 'border-red-600' : 'border-gray-200'
            } border rounded-md`}
          >
            <LockClosedIcon className="w-4 h-4 text-gray-500" />
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
              className="outline-0 grow font-light"
            />
          </div>
          <button
            type="submit"
            className={`block text-center font-semibold mt-5 p-4 w-full rounded-full ${
              isValid ? 'bg-primary-400' : 'bg-gray-400'
            } text-white`}
          >
            {loading ? 'Loading...' : '  Login'}
          </button>
        </form>
      </div>
    </section>
  );
}

export default LoginContent;
