import axiosInstance from '@/utils/https';
import axios from 'axios';

export const GetOrders = async () => {
  try {
    const url = `api/v1/order/me`;
    const response = await axiosInstance.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const UpdateOrderDetails = async (
  id: string,
  payload: {
    address?: string;
    note?: string;
    scheduledTime?: string;
    scheduledDate?: string;
  }
) => {
  try {
    const url = `api/v1/order/${id}`;
    const res = await axiosInstance.patch(url, payload);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const UpdateUserOrderDetails = async (orderid: string) => {
  try {
    const url = `api/v1/order/user/${orderid}`;
    const res = await axiosInstance.patch(url);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const GetOneOrder = async (id: string) => {
  try {
    const url = `api/v1/orders/${id}`;
    const response = await axiosInstance.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const ConfirmOrders = async (id: string, payload: any) => {
  try {
    const url = `api/v1/orders/order/confirm/${id}`;
    const response = await axiosInstance.patch(url, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const EditOrders = async (id: string, payload: any) => {
  try {
    const url = `api/v1/orders/${id}`;
    const response = await axiosInstance.patch(url, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const CancelOrders = async (id: string, payload: any) => {
  try {
    const url = `api/v1/orders/${id}/cancel`;
    const response = await axiosInstance.patch(url, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetUserLatAndLong = async (id: string) => {
  try {
    const url = `api/v1/outlets/maps?place_id=${id}`;
    const response = await axiosInstance.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetNearbyOutlets = async (lat: number, long: number) => {
  try {
    const url = `api/v1/outlets/nearby?lat=${lat}&long=${long}`;
    const response = await axiosInstance.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetOutletsAroundMe = async (lat: number, long: number) => {
  try {
    const url = `api/v1/outlets?lat=${lat}&long=${long}`;
    const response = await axiosInstance.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetAddress = async (address: string): Promise<any> => {
  try {
    const url = `api/v1/outlets/map/autocomplete?input=${address}`;
    const res = await axiosInstance.get(url);

    return res.data;
  } catch (error) {}
};

export const GetOutletMenu = async (id: string): Promise<any> => {
  const url = `api/v1/menu/${id}`;
  const res = await axiosInstance.get(url);

  return res.data;
};

export const AddAddress = async (payload: any) => {
  try {
    const url = 'api/v1/users/address';
    const res = await axiosInstance.post(url, payload);

    return res.data;
  } catch (err) {
    throw err;
  }
};

export const DeleteAddress = async (id: string) => {
  try {
    const url = `api/v1/users/address/${id}`;
    const res = await axiosInstance.delete(url);

    return res.data;
  } catch (err) {
    throw err;
  }
};

export const GetMyAddresses = async () => {
  try {
    const url = 'api/v1/users/address';
    const res = await axiosInstance.get(url);

    return res.data;
  } catch (err) {
    throw err;
  }
};
