import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import AppStorage from '@/utils/helpers';

// type CartItem = {
//   id: number;
//   name: string;
//     quantity: number;
//     outlet_id: string;
//     unit_price: number;
// single_menu_item: string

// };

type CartState = {
  cart: any[];
  orderData: null;
  cartModalIsOpen: boolean;
  coupon: string;
  relatedCartItems:any[]
};

const loadCartFromAppStorage = () => {
  if (typeof window !== 'undefined') {
    const cartJSON = AppStorage.get('cart');
    return cartJSON ? JSON.parse(cartJSON) : [];
  }
};

const initialState: CartState = {
  cart: loadCartFromAppStorage(),
  orderData: null,
  cartModalIsOpen: false,
  coupon: '',
  relatedCartItems: []
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, { payload }: PayloadAction<any>) => {
      const existingItem = state.cart.find((item) => item.id === payload.id);
      if (existingItem) {
        existingItem.quantity += 1;
        toast.success('Item added to cart!');
      } else {
        state.cart.push({ ...payload, quantity: 1 });
        toast.success('Item quantity increased in cart!');
      }

      saveCartToAppStorage(state.cart);
    },
    removeFromCart: (state, { payload }: PayloadAction<number>) => {
      state.cart = state.cart.filter((item) => item.id !== payload);
      toast.success('Item removed from cart!');

      saveCartToAppStorage(state.cart);
    },
    incrementQuantity: (state, { payload }: PayloadAction<number>) => {
      const item = state.cart.find((item) => item.id === payload);
      if (item) {
        item.quantity += 1;
        toast.success('Item quantity increased in cart!');
      }

      saveCartToAppStorage(state.cart);
    },
    decrementQuantity: (state, { payload }: PayloadAction<number>) => {
      const item = state.cart.find((item) => item.id === payload);
      if (item && item.quantity > 1) {
        item.quantity -= 1;
        toast.success('Item quantity reduced in cart!');
      }

      saveCartToAppStorage(state.cart);
    },
    clearCart: (state) => {
      state.cart = [];
      AppStorage.remove('cart');
    },
    addToOrderData: (state, { payload }: PayloadAction<any>) => {
      state.orderData = payload;
    },
    updateCartModalState: (state, { payload }: PayloadAction<boolean>) => {
      state.cartModalIsOpen = payload;
    },
    setCoupon: (state, { payload }: PayloadAction<string>) => {
      state.coupon = payload;
    },
    setRelatedCartItems: (state, { payload }: PayloadAction<any>) => {
      state.relatedCartItems = payload;
    }
  },
});

const saveCartToAppStorage = (cart: any[]) => {
  AppStorage.save('cart', JSON.stringify(cart));
};

const { actions, reducer: CartReducer } = cartSlice;

export const {
  addToCart,
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
  clearCart,
  addToOrderData,
  updateCartModalState,
  setCoupon,
  setRelatedCartItems,
} = actions;
export default CartReducer;
